.keyboardNavigator {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.keyboardNavigator__item {
    width: 100%;
    border-radius: 14px;
    padding: 0 12px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
}

.keyboardNavigator__item:hover {
    background-color: rgba(255,255,255,0.08);
}


.keyboardNavigator__item:focus {
    outline: none;
    border: none;
}

.selected {
    background-color: rgba(255,255,255,0.08);
    border: "none";
}
