@font-face {
  font-family: 'EudoxusSans-ExtraLight';
  src: local('EudoxusSans-ExtraLight'), url('fonts/EudoxusSans-ExtraLight.ttf') format('truetype');
  font-weight: extra-light;

}

@font-face {
  font-family: 'EudoxusSans';
  src: local('EudoxusSans'), url('fonts/EudoxusSans-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'EudoxusSans-Bold';
  src: local('EudoxusSans-Bold'), url('fonts/EudoxusSans-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'EudoxusSans-ExtraBold';
  src: local('EudoxusSans-ExtraBold'), url('fonts/EudoxusSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

@media print {
  body {
    color: black;
  }
}

/**
* Global Styles
*/
* {
  font-family: 'Inter', sans-serif !important;
  /* font-family: 'Montserrat', sans-serif !important; */
  /* font-family: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif !important; */
}

h1, h2, h3, h4, h5 {
  font-family: Graphik,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif !important;
}

h6 {
  font-size: 11px !important;
  letter-spacing: 0.5px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
}

body {
  margin: 0;
  cursor: default;
  background: "background.paper";
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

a {
  text-decoration: none !important;
  color: #376fd0;
  cursor: pointer;
}

kbd {
  border-radius: 8px;
  padding: 0px 8px;
  font-size: 11px;
  height: 20px;
  color: #808080;
  box-shadow: #505050 0px 0px 0px 1px inset;
}

input:-internal-autofill-selected {
  background-color: rgba(0,0,0,0.1) !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: none !important;
  transition: background-color 5000s ease-in-out 0s;
}

.text-field:focus-within {
  border: 2px solid;
  border-color: #376fd0;
  outline: none;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
}

.selected-field {
  border: 2px solid;
  border-radius: 8px;
  border-color: #376fd0;
  outline: none;
  box-shadow: 0 0 12px rgb(0 0 0 / 10%);
}

/*
* PDF Downloads
*/
.contactContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contactRow {
  color: #333;
}

/*
* MUI Data Grid Pro
* https://mui.com/api/data-grid/data-grid-pro/
*/
.MuiDataGrid-root {
  border: none;
}

/* Filter Panel */
.MuiDataGrid-panelWrapper {
  border: 1px solid #444;
  border-radius: 8px;
  padding: 8px;
}

.MuiDataGrid-panelContent .MuiDataGrid-filterForm .MuiFormControl-root .MuiButtonBase-root .MuiSvgIcon-root {
  border-radius: 8px;
  border: 1px solid #444;
  border-radius: 8px;
  height: 25px;
  width: 25px;
  &:hover {
    background-color: #333;
    border-radius: 8px;
  }
}

.MuiDataGrid-panelFooter .MuiButton-root{
  background: transparent;
  color: #fff;
}

.MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-main 
.MuiDataGrid-columnHeaders 
.MuiDataGrid-pinnedColumnHeaders--left 
.MuiDataGrid-columnHeader--sortable 
.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight, .MuiDataGrid-columnSeparator { 
  display: none !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 11px !important;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.5;
}

.MuiDataGrid-cell {
  border-bottom: none !important;
}

.MuiDataGrid-pinnedColumnHeaders {
  border-top-right-radius: 8px;
}

.MuiDataGrid-pinnedColumns {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* 
* Card Header Removal of Padding for IconButton
*/
.MuiCardContent-root:last-child {
  padding-bottom: 16px;
}

/* CustomScrollbar  */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #505050;
  border-radius: 8px;
  height: 2px !important;
  max-height: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Tab ARROW  */
.MuiTabs-scrollButtons {
  opacity: 1;
  margin-top: -8px;
}

.MuiTabs-scrollButtons svg {
  color: black;
  height: 1.6rem !important;
  width: 1.6rem !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 1;
}

/*
* React Quill
* https: //github.com/zenoamaro/react-quill
*/
.ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.24) !important;
}

.ql-toolbar.ql-snow .ql-formats {
  background-color: transparent !important;
  background: transparent !important;
  padding: 0px !important;
  margin-right: 0px !important;
}

.ql-snow .ql-picker {
  font-size: 12px !important;
}

/*
* React Quill Mention
* https: //github.com/quill-mention/quill-mention
*/
.mention {
  font-weight: bold !important;
  background: none !important;
  color: #fff;
  border-radius: 8px;
  padding: 0 !important;
  margin: 0 !important;
}

.mention span {
  margin: 0 !important;
}

.hashtag {
  color: purple !important;
}

.ql-mention-list-container {
  box-shadow: none !important;
  overflow: scroll;
  min-height: fit-content;
  max-height: -webkit-fill-available;
  background-color: #29282B!important;
  border-radius: 10px !important;
  border: 1px solid #333 !important;
  scrollbar-width: thin;
}

.ql-mention-list-container::-webkit-scrollbar {
  width: 3px;
}

.suggestions {
  display: flex;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  background: none !important;
  height: 50px !important;
}

.container {
  padding-top: 8px;
  width: 100%;
  background: none !important;
}

.title {
  font-size: 16px;
  height: 30px;
  color: #fff;
}

.ql-mention-list-item {
  margin: 4px 4px;
  border-radius: 8px;
  background-color: transparent !important;
}

.ql-mention-list-item:hover {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.title,
.designation {
  font-family: "Inter",
    sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.designation {
  height: 20px;
  font-size: 75%;
  color: #376fd0;
  width: 100%;
  margin-top: -0.5rem;
  margin-bottom: 0.75rem;
}

/* calendar fix for event overflow in day  */
/* .fc .fc-daygrid-day-frame {
  width: 100%;
  overflow-x: hidden;
} */

/* .fc .fc-timegrid-slot {
  border-color: transparent;
  background-color: #fff;
} */

/* .fc .fc-timegrid-slot:hover {
  border-radius: 14px !important;
  background-color:rgba(0, 0, 0, 0.25) !important;
} */

.fc .fc-timegrid-axis {
  border-color: transparent;
  display: none;
}

.button-google-css{
text-transform: none;
    line-height: 1.75 !important;
    min-width: 64px !important;
    padding: 5px 15px !important;
    border-radius: 8px !important;
    -webkit-transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms !important;
    transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms !important;
    border: 1px solid rgba(55,111,208,0.5) !important;
    color: #376fd0 !important;
    margin-left: 8px !important;
  box-shadow:  none !important;
}

.button-google-css span{
  margin: 0 !important;
  padding: 0 !important;
     font-family: Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 500 !important;
    font-size: 0.8125rem !important;
}

.button-google-css div{
  margin: 0 !important;
  padding: 0 !important;
  display: flex;
  margin-right: 5px !important;
}

.button-google-css:hover{
  border : 1px solid #376fd0 !important;
  transition: background-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,box-shadow 250ms cubic-bezier(0.4,0,0.2,1) 0ms,border-color 250ms cubic-bezier(0.4,0,0.2,1) 0ms,color 250ms cubic-bezier(0.4,0,0.2,1) 0ms  !important;
}

.custom-date-picker{
  border-radius: 8px;
  padding: 8.5px 14px;
  border: 1px solid #c9cbce;
  width: 100%;
}

.app-down-alert{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

/**
* REACT GRID
*/
.layout {
  
}

.react-grid-item {
  height: 100%;
  width: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 8px;
}

/* 
* ToDesktop
* https://docs.todesktop.com/customizing-your-app/making-a-transparent-titlebar-draggable-macos-only
*/
/* html.todesktop-platform-darwin {
  max-height: calc(100vh - 170px);
} */
.pb-simple-bar__top {
  overflow: hidden;
}

html.todesktop-platform-darwin .pb-simple-bar__top {
  -webkit-app-region: drag
}

/* 
* Canny 
* - Changelog
*/
.cannyChangelog {
  
}

#activityNote {
  height: 100%;
  padding: 0px 8px;
  margin-top: 16px;
  background-color: #31313180;
  border-radius: 14px;
  width: inherit;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 14px;
}

/* 
* Drag and Drop
*/
#form-property-image-upload {
  height: 5rem;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-property-image-upload {
  display: none;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

/* #label-property-image-upload.drag-active {
  background-color: #ffffff;
} */

#drag-property-image-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}


/*
* Maintenance
*/
.maintenance {
    background-image: url(https://demo.wpbeaveraddons.com/wp-content/uploads/2018/02/main-1.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}

.maintenance {
    width: 100%;
    height: 100%;
    min-height: 100vh;
}

.maintenance {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.maintenance_contain {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;  
    padding: 15px;  
}

.maintenance_contain img {
    width: auto;
    max-width: 100%;
}

.pp-infobox-title-prefix {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-top: 30px;
    text-align: center;
}

.pp-infobox-title-prefix {
    font-family: sans-serif;
}

.pp-infobox-title {
    color: #000000;
    font-family: sans-serif;
    font-weight: 700;
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    display: block;
    word-break: break-word;  
}

.pp-infobox-description {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
}

.pp-infobox-description p {
    margin: 0;
}

.title-text.pp-primary-title {
    color: #000000;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    font-family: sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 50px;
    margin-bottom: 0px;
}

.pp-social-icon {
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    line-height: 0;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}

.pp-social-icon a {
    display: inline-block;
    height: 40px;
    width: 40px;
}

.pp-social-icon a i {
    border-radius: 100px;
    font-size: 20px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
}

.pp-social-icon:nth-child(1) a i {
    color: #4b76bd;
}
.pp-social-icon:nth-child(1) a i {
    border: 2px solid #4b76bd;
}
.pp-social-icon:nth-child(2) a i {
    color: #00c6ff;
}
.pp-social-icon:nth-child(2) a i {
    border: 2px solid #00c6ff;
}
.pp-social-icon:nth-child(3) a i {
    color: #fb5245;
}
.pp-social-icon:nth-child(3) a i {
    border: 2px solid #fb5245;
}
.pp-social-icon:nth-child(4) a i {
    color: #158acb;
}
.pp-social-icon:nth-child(4) a i {
    border: 2px solid #158acb;
}

.pp-social-icons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}

.property-card {
  &:last-child {
    -webkit-mask-image: linear-gradient(180deg, #000 1%, transparent);
  }
}
